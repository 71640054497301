// Header.js
import React from 'react';
import './Header.css'; // Ensure you have this CSS file

const Header = () => {
  return (
    <div className="header-container">
     <img src="/logo4.png" alt="Logo" className="header-logo" />
      <h1 className="header-title">Royal Rays</h1>
    </div>
  );
};

export default Header;




