import React from 'react';

const Contactus = () => {
  return (
    <div style={{ backgroundColor: '#414143', padding: '20px', color: 'white' }}> {/* Changed to dark grey and text color to white */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
        {/* Contact Form Style */}
        <div style={{ width: '45%' }}>
          <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>CONTACT FORM STYLE</h2> {/* Adjusted line color */}
          <form action="https://formspree.io/f/xjvqqgbw" method="POST" >
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="name" style={{ color: 'white' }}>Name *</label> {/* Text color changed */}
              <input type="text" id="name" name="name" placeholder="Full Name" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="email" style={{ color: 'white' }}>Email *</label> {/* Text color changed */}
              <input type="email" id="email" name="email" placeholder="your@email.com" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="subject" style={{ color: 'white' }}>Subject *</label> {/* Text color changed */}
              <input type="text" id="subject" name="subject" placeholder="Subject" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="message" style={{ color: 'white' }}>Your Message</label> {/* Text color changed */}
              <textarea id="message" name="message" placeholder="Write Your Message" style={textareaStyle} />
            </div>
            <button type="submit" style={buttonStyle}>Send Your Message</button>
          </form>
        </div>

        {/* Contact Info Style */}
        <div style={{ width: '45%' }}>
          <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>CONTACT INFO STYLE</h2> {/* Adjusted line color */}
          <p style={{ color: 'white' }}><strong>WHERE TO REACH US</strong></p> {/* Text color changed */}
          <p style={{ color: 'white' }}>You can reach us at the following<br/>Address: Bharat Diamond Bourse, Mumbai - 400051.</p> {/* Text color changed */}
          <p style={{ color: 'white' }}><strong>EMAIL US @</strong></p> {/* Text color changed */}
          <p style={{ color: 'white' }}>Email your issues and suggestion for the following<br/>Email Addresses: bvroyalrays@gmail.com</p> {/* Text color changed */}
          
          
          <div style={{ width: '75%', height: '320px', marginTop: '20px', marginLeft: '0', marginRight: '25%' }}> {/* Adjusted width and height */}
          <div style={{ width: '90%', height: '320px', marginTop: '20px', marginLeft: 'auto', marginRight: '25%' }}>
  <iframe
    title="Our Location"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9860628326783!2d72.86135697497707!3d19.064350382137864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9db48bcc7d1%3A0x52c692071ad14131!2sBharat%20Diamond%20Bourse%20-%20Mumbai!5e0!3m2!1sen!2sin!4v1700820177087!5m2!1sen!2sin"
    width="100%" 
    height="100%" 
    style={{ border: '0' }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
</div>
</div>



        </div>
      </div>
        </div>
        
      
    
  );
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '6px 0',
  display: 'inline-block',
  border: '1px solid #ccc',
  backgroundColor: '#616161', // Input background color
  color: 'white', // Text color
  boxSizing: 'border-box'
};

const textareaStyle = {
  ...inputStyle,
  height: '150px',
  resize: 'vertical'
};

const buttonStyle = {
  backgroundColor: '#d3d3d3', // Transparent background for the button
  color: 'black', // Text color
  padding: '14px 20px',
  margin: '8px 0',
  border: 'none',
  cursor: 'pointer',
  width: '100%',
  opacity: '0.9'
};

export default Contactus;
