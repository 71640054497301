import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import Header from './components/Header';
import Events from "./components/Events";
import Contact from './components/Contact';
import Footer from './components/Footer';
import Journey from "./components/Journey";
import Policies from "./components/Policies";
import Rjc from "./components/Rjc";
import Provenance from "./components/Provenance";
import What from "./components/What";
import Contactus from "./components/Contactus";
import Custom from "./components/Custom";
function App() {
  return (
    <Router>
    <div>
      <Navbar />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/the-journey" element={<Journey />} />
        <Route path="/compliance-1" element={<Policies />} />
        <Route path="/compliance-2" element={<Rjc />} />
        <Route path="/compliance-3" element={<Provenance />} />
        <Route path="/whatwedo" element={<What />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/Customization" element={<Custom />} />
 
      </Routes>
      <Footer /> 
    </div>
  </Router>
  );
}

export default App;



